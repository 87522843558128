import forceTrailingSlash from "../../templates/utils/forceTrailingSlash";

export interface IBreadCrumb {
  name: string;
  path: string;
}

export interface IBreadCrumbSchema {
  "@context": "https://schema.org";
  "@type": "BreadcrumbList";
  itemListElement: Array<{
    "@type": "ListItem";
    position: number;
    name: string;
    item: string;
  }>;
}

const breadCrumbSchema = (
  breadcrumbs: IBreadCrumb[]
): IBreadCrumbSchema => {
  const breadCrumbObj: IBreadCrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };

  // Set the breadcrumb item element data for each breadcrumb given
  for (const i in breadcrumbs) {
    if (breadcrumbs[ i ]) {
      const breadcrumb = breadcrumbs[ i ];

      breadCrumbObj.itemListElement.push({
        "@type": "ListItem",
        "position": parseInt(i, 0) + 1,
        "name": breadcrumb.name,
        "item": forceTrailingSlash(
          `https://www.gravitywell.co.uk${breadcrumb.path}`
        )
      });
    }
  }

  return breadCrumbObj;
};

export default breadCrumbSchema;
