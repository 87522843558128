export interface IPostalAddressSchema {
  "@type": "PostalAddress";
  name: "Gravitywell";
  streetAddress: "12 Colston Yard";
  postalCode: "BS1 5BD";
  addressLocality: "Bristol";
  addressRegion: "Avon";
  addressCountry: "GB";
}

const postalAddressSchema: IPostalAddressSchema = {
  "@type": "PostalAddress",
  "name": "Gravitywell",
  "streetAddress": "12 Colston Yard",
  "postalCode": "BS1 5BD",
  "addressLocality": "Bristol",
  "addressRegion": "Avon",
  "addressCountry": "GB"
};

export default postalAddressSchema;
