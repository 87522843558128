import GwCoverImg from "../../assets/images/g-schema/gravitywell-cover.jpg";
import postalAddressSchema, { IPostalAddressSchema } from "./postal-address-schema";

export interface ILocalBusinessSchema {
  "@context": "http://schema.org";
  "@type": "LocalBusiness";
  "@id": "https://www.gravitywell.co.uk/#localbusiness";
  legalName: "Hayward & Bos Ltd";
  name: "Gravitywell";
  vatID: "GB-194038302";
  email: "hello@gravitywell.co.uk";
  url: "https://www.gravitywell.co.uk/";
  telephone: "+44 117 379 0309";
  address: IPostalAddressSchema;
  geo: {
    "@type": "GeoCoordinates";
    latitude: 51.457206;
    longitude: -2.5972232;
  };
  OpeningHoursSpecification: Array<{
    dayOfWeek: string[];
    opens: "09:00";
    closes: "17:30";
  }>;
  priceRange: "$$$";
  image: string[];
}

const localBusinessSchema: ILocalBusinessSchema = {
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  "@id": "https://www.gravitywell.co.uk/#localbusiness",
  "legalName": "Hayward & Bos Ltd",
  "name": "Gravitywell",
  "vatID": "GB-194038302",
  "email": "hello@gravitywell.co.uk",
  "url": "https://www.gravitywell.co.uk/",
  "telephone": "+44 117 379 0309",
  "address": postalAddressSchema,
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 51.457206,
    "longitude": -2.5972232
  },
  "OpeningHoursSpecification": [
    {
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      opens: "09:00",
      closes: "17:30"
    }
  ],
  "priceRange": "$$$",
  "image": [ `https://www.gravitywell.co.uk${GwCoverImg}` ]
};

export default localBusinessSchema;
