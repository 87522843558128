import Img from "gatsby-image";
import * as React from "react";

import {
  IFixed,
  IFluid,
  ILocalFile
} from "src/types/local-files";

interface IProps {
  className?: string;
  data: ILocalFile<IFluid & IFixed> | null;
  alt?: string;
}

/**
 * Returns the appropriate image depending on the given data
 */
const DynamicImage = ({
  className, data, alt
}: IProps) => {
  if (!data || !data.localFile) {
    return null;
  }

  const localFile = data.localFile;
  const childImageSharp = localFile.childImageSharp;

  if (childImageSharp && (childImageSharp.fluid || childImageSharp.fixed)) {
    return (
      <Img
        className={className}
        fluid={childImageSharp.fluid ? childImageSharp.fluid : []}
        fixed={childImageSharp.fixed ? childImageSharp.fixed : []}
        alt={alt || data.filename}
      />
    );
  }

  return (
    <img
      className={`full ${className}`}
      src={data.localFile.publicURL}
      alt={alt || data.filename}
    />
  );
};

export default DynamicImage;
