import forceTrailingSlash from "../../templates/utils/forceTrailingSlash";
import organizationSchema, { IOrganizationSchema } from "./organization-schema";

interface IArticle {
  type: "Article" | "NewsArticle" | "BlogPosting";
  title: string;
  description: string;
  path: string;
  datePublished: string;
  dateModified: string;
  author: string;
  imageUrls: string[];
}

export interface IArticleSchema {
  "@context": "https://schema.org";
  "@type": "Article" | "NewsArticle" | "BlogPosting";
  mainEntityOfPage: {
    "@type": "WebPage";
    "@id": string;
  };
  headline: string;
  datePublished: string;
  dateModified: string;
  author: {
    "@type": "Person";
    name: string;
  };
  image: string[];
  publisher: IOrganizationSchema;
  description: string;
}

const articleSchema = (articleData: IArticle): IArticleSchema => {
  const images: string[] = articleData.imageUrls.map((imgPath: string) => {
    return `https://www.gravitywell.co.uk${imgPath}`;
  });

  return {
    "@context": "https://schema.org",
    "@type": articleData.type,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": forceTrailingSlash(
        `https://www.gravitywell.co.uk${articleData.path}`
      )
    },
    "headline": articleData.title,
    "description": articleData.description,
    "image": images,
    "datePublished": articleData.datePublished,
    "dateModified": articleData.dateModified,
    "author": {
      "@type": "Person",
      "name": articleData.author
    },
    "publisher": organizationSchema
  };
};

export default articleSchema;
