export interface IWebsiteSchema {
  "@context": "https://schema.org";
  "@type": "WebSite";
  url: "https://www.gravitywell.co.uk/";
}

const websiteSchema: IWebsiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://www.gravitywell.co.uk/"
};

export default websiteSchema;
