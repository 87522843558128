import GWLogoImg from "../../assets/images/gw-logo-512-transparent.png";
import { contactPointSchema } from "./contact-point-schema";
import postalAddressSchema, { IPostalAddressSchema } from "./postal-address-schema";

export interface IOrganizationSchema {
  "@context": "https://schema.org";
  "@type": "Organization";
  "@id": "https://www.gravitywell.co.uk/#organization";
  legalName: "Hayward & Bos Ltd";
  name: "Gravitywell";
  vatID: "GB-194038302";
  email: "hello@gravitywell.co.uk";
  url: string;
  logo: {
    "@type": "ImageObject";
    url: string;
    width: number;
    height: number;
  };
  address: IPostalAddressSchema;
  contactPoint: Array<typeof contactPointSchema>;
  sameAs: string[];
}

const organizationSchema: IOrganizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "@id": "https://www.gravitywell.co.uk/#organization",
  "legalName": "Hayward & Bos Ltd",
  "name": "Gravitywell",
  "vatID": "GB-194038302",
  "email": "hello@gravitywell.co.uk",
  "url": "https://www.gravitywell.co.uk/",
  "logo": {
    "@type": "ImageObject",
    "url": `https://www.gravitywell.co.uk${GWLogoImg}`,
    "width": 512,
    "height": 512
  },
  "address": postalAddressSchema,
  "contactPoint": [ contactPointSchema ],
  "sameAs": [
    "https://twitter.com/gravitywelluk/",
    "https://www.facebook.com/gravitywelluk/",
    "https://www.instagram.com/gravitywelluk/",
    "https://www.linkedin.com/company/gravitywell/"
  ]
};

export default organizationSchema;
